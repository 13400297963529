import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {
    Container,
    Row,
    Col, Badge
} from "react-bootstrap";

export default function Footer() {
    return (
            <Container fluid>
                <Row>
                    <Col sm="12"><hr/></Col>
                </Row>
                <Row xs={2}>
                    <Col>
                        <small>
                            <Badge><i className="bi bi-link"/></Badge>&nbsp;
                            <a href="https://bowlerblue.com/" target="_blank" rel="noreferrer">BowlerBlue Limited</a>
                        </small>
                    </Col>
                    <Col>
                        <small className="float-end">
                            <Badge><i className="bi bi-envelope-open"/></Badge>&nbsp;
                            <a href="mailto:kya@bowlerblue.uk">kya@bowlerblue.uk</a>
                        </small>
                    </Col>
                </Row>
            </Container>

    );
}
