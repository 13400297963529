import { Row, Col, Image } from "react-bootstrap";

import { ReactComponent as LargeWhiteInfinity } from "./img/inf-100px-wht.svg";
import { ReactComponent as SmallWhiteInfinity } from "./img/inf-50px-wht.svg";
import { ReactComponent as ExtraSmallWhiteInfinity } from "./img/inf-25px-wht.svg";
import { ReactComponent as ExtraSmallTransparentInfinity } from "./img/inf-25px-trns.svg";
import { ReactComponent as ExtraSmallWhiteSpinner } from "./img/spin-25px-wht.svg";

export { LargeWhiteInfinity, SmallWhiteInfinity, ExtraSmallWhiteInfinity, ExtraSmallTransparentInfinity, ExtraSmallWhiteSpinner };

export function LargeWhiteInfinityRow() {
    return(
        <>
            <Row>
                <Col className="text-center">
                    <LargeWhiteInfinity/>
                </Col>
            </Row>
        </>
    );
}

export function SmallWhiteInfinityCol() {
    return (
            <>
                <Col className="text-center">
                    <SmallWhiteInfinity/>
                </Col>
            </>
    );
}

export function ExtraSmallTransparentSpacer() {
    return (
            <>
                <Image src="/img/spacer-25px-trns.png"/>
            </>
    );
}

