import './App.css';
import React, { Suspense, lazy } from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {
        Container,
        Row,
        Col
} from "react-bootstrap";

import Footer from "./common/Footer"
import Navigation from "./common/Navigation";
import { LargeWhiteInfinityRow } from "./common/Spinners";

const Home = lazy(() => import("./home/Home"));
const SmartHome = lazy(() => import("./smartHome/SmartHome"));
const Bins = lazy(() => import("./bins/Bins"));
const Settings = lazy(() => import("./settings/Settings"));
const About = lazy(() => import("./about/About"));
const Games = lazy(() => import("./games/Games"));
const Weather = lazy(() => import("./weather/Weather"));
const Error = lazy(() => import("./error/Error"));

/**
 * The main App component
 * Handles global state for the application
 */
class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = { }; // Full on JSON object with state data here.  Reference using {this.state.*}
    }

    render() {
        return (<>
                    <Router>

                        <Container fluid="xxxl">

                            <Navigation title="BowlerBlue - Kya"/>

                            <Suspense fallback={<LargeWhiteInfinityRow/>}>
                                <Routes>
                                    <Route path="/" element={<Home/>} errorElement={<Error/>} />
                                    <Route path="/weather" element={<Weather/>} />
                                    <Route path="/smartHome" element={<SmartHome/>} />
                                    <Route path="/bins" element={<Bins/>} />
                                    <Route path="/games" element={<Games/>} />
                                    <Route path="/settings" element={<Settings/>} />
                                    <Route path="/about" element={<About/>} />
                                    <Route path="/error" element={<Error/>} /> { /* Explicitly map the /error route - but technically not necessary cause next line will catch it. */ }
                                    <Route path="/*" element={<Error/>} /> { /* All unknown routes will get caught here. */ }
                                </Routes>
                            </Suspense>

                            <Footer/>

                        </Container>

                    </Router>
                </>
        );
    }

}


export default App;
