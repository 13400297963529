import React from "react";
import {
        Nav,
        Navbar,
        Row,
        Col
} from "react-bootstrap";
import {
    Link,
    NavLink
} from "react-router-dom";

type NavigationProps = {
    title: string;
};

class Navigation extends React.Component<NavigationProps> {

    render() {
        return (
            <>
                <Row>
                    <Col xs="12">
                        <Navbar collapseOnSelect={true} bg="primary" variant="dark" expand="lg">
                            <Navbar.Brand as={Link} to="/" className="mx-2">
                                <i className="bi bi-house-fill"/>&nbsp;{this.props.title}
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="mx-2"/>
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="me-auto">
                                    <Nav.Link as={NavLink} eventKey="home" to="/"><i className="bi bi-house"/>&nbsp;Home</Nav.Link>
                                    <Nav.Link as={NavLink} eventKey="weather" to="/weather"><i className="bi bi-cloud-sun"/>&nbsp;Weather</Nav.Link>
                                    <Nav.Link as={NavLink} eventKey="smartHome" to="/smartHome"><i className="bi bi-toggles"/>&nbsp;Smart Home</Nav.Link>
                                    <Nav.Link as={NavLink} eventKey="bins" to="/bins"><i className="bi bi-trash3"/>&nbsp;Bins</Nav.Link>
                                    <Nav.Link as={NavLink} eventKey="games" to="/games"><i className="bi bi-joystick"/>&nbsp;Games</Nav.Link>
                                    <Nav.Link as={NavLink} eventKey="settings" to="/settings"><i className="bi bi-gear"/>&nbsp;Settings</Nav.Link>
                                    <Nav.Link as={NavLink} eventKey="about" to="/about"><i className="bi bi-info-circle"/>&nbsp;About</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </Col>
                </Row>
            </>
        );
    }

}

export default Navigation;